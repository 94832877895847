.App {
  text-align: center;
  margin: 0 auto;
  width: 90%;
  max-width: 500px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body {
  font-family: 'Cutive Mono', monospace;
  background: var(--background);
  padding: 0;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.toggleButton {
  position: absolute;
  top: 10px;
  right: 10px;
}

.toggleButton button {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #f0ed48;
  padding: 0;
  margin: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 530px) {
  body {
    padding: 0 30px;
  }
  .App-header {
    flex-direction: column;
  }
}
