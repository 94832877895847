.hero {
    display: grid;
    margin-top: 100px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    }

.hero .text {
    grid-area: text;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .hero .text div {

} */

.hero .imageContainer {
    grid-area: image;
    text-align: center;
}

.hero .imgMain {
    max-width: 500px;
    height: 300px;
    width: 300px;
    overflow: hidden;
    border: 1px solid #021a40;
}


.hero--imgAlignLeft {
    grid-template-areas:
    "image text";
}

.hero--imgAlignRight {
    grid-template-areas:
    "text image";
}